import React from "react"
import ReactMarkdown from "react-markdown"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogPostTemplate = ({ data }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const featuredImage = getImage(post.frontmatter.featuredImage?.childImageSharp?.gatsbyImageData)

  return (
    <Layout title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
      />
      <article className="avenir pb3">
        <section className="mw8 center">
          <header className="avenir tc-l ph3 ph4-ns pt4 pt5-ns mb5 bb b--near-white">
            <h1 className="f3 f2-m f-subheadline-l measure lh-title fw1 mt0">
              {post.frontmatter.title}
            </h1>
            <div className="flex db mb4">
              <time className="f5 f4-l db fw1 avenir mb4-l mb2">
                {post.frontmatter.date}
              </time>
            </div>
          </header>
          <ReactMarkdown
            className="ph3 ph4-m ph5-l mw7 fw2 db center f5 f4-ns lh-copy bl br b--near-white"
            children={post.internal.content}
          />
          <div className="ph3 ph4-m ph5-l mw7 fw2 db center f5 f4-ns lh-copy bl br b--near-white"> <GatsbyImage image={featuredImage} className="center"/> </div>
        </section>
      </article>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      internal {
        content
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 400)
          }
        }
      }
    }
  }
`
